import React from 'react';
import Card from 'react-bootstrap/Card';
import AdSense from 'react-adsense';

const Home = (props) => {
    return (
        <>
        <div className="row">
            <div className="col-lg-12">
                <h3 style={{ fontSize: '1.5rem', color: '#FFD4D4' }}>Oops! 暫停服務</h3>

                <Card style={{ 'backgroundColor': '#666666', minHeight: '75vh', borderRarius: '1rem' }} body>
                    <div style={{ minHeight: '70vh', display: 'flex', flexDirection: 'column', alignContent: 'space-between' }}>
                        <div style={{ alignItems: 'flex-end', flexGrow: '1', fontSize: '1.4rem' }}>
                            <p>感謝大家的使用，已於 2020/07/23 起暫停服務</p>
                            <p>先前伺服器狀況越來越多，人力關係無法繼續維護。</p>
                            <br />
                            <p>為了品質與安全，我們決定暫停此收藏平台</p>
                            <p>未來有不同想法或服務將發佈於<a href="https://www.facebook.com/ptt.forward" target="_blank" style={{ color: '#FFFBBB' }}>粉絲團</a></p>
                            <br />
                            <p>再次感謝大家這些年來的支持</p>
                        </div>
                        <div style={{ alignItems: 'flex-end' }}>PTT文章收藏轉寄 謝謝您</div>
                    </div>
                </Card>

            </div>
        </div>
        <div className="row">
            <div className="col-lg-12" style={{ minHeight:'10vh'}}>
                    <AdSense.Google
                        client='ca-pub-7384466214086986'
                        slot='1064242104'
                        style={{ display: 'block', minHeight: '10vh' }}
                        layout='in-article'
                        format='auto'
                    />
            </div>
        </div>
        
        </>
    )
}

export default Home;