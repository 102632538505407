import React from 'react';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';

const About = (props) => {
    return (
        <> 
            <div className="row">
                <div className="col-lg-4 text-left">
                    <Link to="/">
                        <Button size="sm" variant="secondary">返回首頁</Button>
                    </Link>
                </div>
               <div className="col-lg-4 text-center">
                    <div style={{ fontSize: '1.5rem' }}>關於我們</div>
               </div>
            </div>
            <div className="row">
                <div className="col-lg-12 text-center">
                    
                </div>
            </div>
        </>
    )
}

export default About;