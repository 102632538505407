import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';

const NotFound = (props) => {
    return (
        <>
            <Redirect to='/' />
        </>
    )
}

export default NotFound;