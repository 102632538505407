import React from 'react';

const LinkStyle={
    'color':'#999999',
    'fontSize':'1rem'
}

const Footer = (props)=>{
    return (
        <div className="footer">
            <div className="row"> 
                <div className="col-lg-4"></div>
                <div className="col-lg-4 text-center">
                    <a style={LinkStyle} href="https://www.facebook.com/ptt.forward" target="_blank">
                        <img style={{ width: '24px' }} src={process.env.PUBLIC_URL + "/fb.png"}></img>
                        <span>粉絲團</span>
                    </a>
                </div>
                <div className="col-lg-4 text-right">
                    <a style={LinkStyle} href="https://icons8.com/" target="_blank">
                        <span>Icon pack by Icons8</span>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Footer;