//import { Home, About } from './pages';
import Home from './pages/Home';
import About from './pages/About';
import NotFound from './pages/NotFound';

const routes = [
    {
        path: '/',
        component: Home,
        exact: true
    },
    {
        path: '/about',
        component: About
    },
    {
        type: 'notFound',
        component: NotFound
    }
];

export default routes;