import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import Header from './components/Header';
import Footer from './components/Footer';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import routes from './routes';
import MetaTags from 'react-meta-tags';

import ReactGA from 'react-ga';
ReactGA.initialize('UA-6385001-4');
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  return (
    <>
    <div className="App">
      <MetaTags>
        <title>PTT文章收藏轉寄</title>
      </MetaTags>
      <div className="App-Body">
        <div className="container-lg">
          <Header />
          {/* 內容ROUTE - start*/}
          <Router>
            <Switch>
              {routes.map((route, i) => {
                const { type , path, exact, routes } = route;
                if (type == 'notFound') {
                  return (
                    <Route
                      key={i}
                      render={(routeProps) => (
                        <route.component routes={routes} {...routeProps} />
                      )}
                    />
                  );
                }

                return (
                    <Route
                      key={i}
                      path={path}
                      exact={exact}
                      render={(routeProps) => (
                        <route.component routes={routes} {...routeProps} />
                      )}
                    />
                );
              })}
            </Switch>
          </Router>
          {/*內容ROUTE - end*/}
          <Footer />
        </div>
      </div>
    </div>
    </>
  );
}


const script = document.createElement('script');

script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
script.async = true;

document.body.appendChild(script);

export default App;